<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.89234 56.36C13.536 49.0104 25.6904 49.2487 33.04 56.8923L128 157.699L222.96 56.8923C230.31 49.2487 242.464 49.0104 250.108 56.36C257.751 63.7097 257.99 75.8641 250.64 83.5077L141.84 198.708C138.22 202.472 133.223 204.6 128 204.6C122.777 204.6 117.78 202.472 114.16 198.708L5.36003 83.5077C-1.9896 75.8641 -1.75128 63.7097 5.89234 56.36Z"
      fill="currentColor"
    />
  </svg>
</template>
